import React from 'react';

import { Main, PrimaryBackground } from '../styles/background';
import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { MigrationContent } from '../components/pageComponents/migration';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const Migration = () => {
  return (
    <PageContainer>
      <HelmetContainer currentPage={'migration'} title="Migration"  />
      <Main noPadding>
        <Header />
      </Main>
      <PrimaryBackground>
        <Content>
          <MigrationContent />
        </Content>
      </PrimaryBackground>
      <Footer  />
    </PageContainer>
  );
};

export default Migration;
