import styled from 'styled-components';
import { media, spacing, typography } from '../../foundations';
import { subStyle } from '../../../styles/typography';
import { Link } from '../../subComponents/Link';
import { colors, mediaQueriesSizes } from '../../../GlobalStyles';

export const ContentContainer = styled.article`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 96px min-content 1fr min-content;
  grid-template-areas: 'title title title title' '. introText . sub' '. link . .';

  width: 100%;
  margin-bottom: 100px;

  @media (max-width: ${media.xl}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, min-content);
    grid-template-areas: 'title title' 'sub .' '. introText' '. link';
  }

  @media screen and (max-width: ${media.lg}px) {
    grid-template-areas: 'title title' 'sub .' 'introText introText' 'link .';
  }

  @media screen and (max-width: ${media.sm}px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'title' 'sub' 'introText' 'link';
  }
`;

export const Title = styled.h1`
  grid-area: title;

  width: 610px;

  margin-top: 150px;
  margin-bottom: 80px;
  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};
  line-height: 1;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const Sub = styled.sub`
  grid-area: sub;

  display: block;
  width: 460px;

  color: ${colors.quaternaryAccent};

  text-align: right;

  font-family: freightBigPro, sans-serif;
  font-size: ${typography.fontSizes['xxxl+']}px;
  font-weight: ${typography.fontWeights.normal};
  line-height: 1;

  @media (max-width: ${media.xl}px) {
    text-align: left;
  }

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const IntroText = styled.p`
  grid-area: introText;

  width: 460px;
  margin-top: 70px;

  color: ${colors.tertiary};

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
  }
`;

export const IntroLink = styled.a`
  grid-area: link;

  width: 200px;
  margin-top: ${spacing.xxxl}px;
  padding-bottom: ${spacing.xs}px;

  color: ${colors.secondary};
  text-decoration: none;

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.medium};

  border-bottom: solid 2px ${colors.quaternaryAccent};
`;

export const MigrationValuesContainer = styled.article`
  display: grid;

  grid-template-rows: repeat(2, auto) repeat(2, 315px);
  grid-template-columns: repeat(3, 380px) 1fr;
  grid-template-areas: 'title title . .' '. . sub sub' 'skillCard1 skillCard2 . .' 'skillCard3 skillCard4 skillCard5 .';

  justify-content: center;
  padding: 180px 0;

  @media (max-width: ${media.xl}px) {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'title title' '. sub' 'skillCard1 skillCard2' 'skillCard3 skillCard4' 'skillCard5 .';

    gap: 30px 30px;
  }

  @media screen and (max-width: ${media.lg}px) {
    grid-template-areas: 'title title' 'sub sub' 'skillCard1 skillCard2' 'skillCard3 skillCard4' 'skillCard5 .';
  }

  @media screen and (max-width: ${media.md}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: 'title' 'sub' 'skillCard1' 'skillCard2' 'skillCard3' 'skillCard4' 'skillCard5';

    gap: 0;
    padding-bottom: 0;
  }
`;

export const ValuesTitle = styled.h2`
  grid-area: title;

  width: 555px;
  margin-bottom: ${spacing.xl}px;

  color: ${colors.quaternaryAccent};

  font-family: freightBigPro, sans-serif;
  font-size: ${typography.fontSizes['xxxl+']}px;
  font-weight: ${typography.fontWeights.normal};
  line-height: 1.06;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const ValuesSub = styled.sub`
  grid-area: sub;

  margin-bottom: ${spacing.xxl}px;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.medium};
  letter-spacing: 0.4px;
  line-height: 1.23;
`;

export const MigrationOpenContainer = styled.article`
  color: ${colors.primary};

  padding-top: 125px;
  padding-bottom: ${spacing.xl}px;

  display: grid;
  grid-template-columns: 380px 1fr min-content;
  grid-template-rows: repeat(6, min-content) 1fr;
  grid-template-areas: 'openSub . .' 'openTitle . .' 'openText . .' 'openPositions . openSection' 'openContact . openSection' '. . openSection';

  @media (max-width: ${media.xl}px) {
    grid-template-rows: repeat(7, min-content);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'openSub .' 'openTitle .' 'openText .' 'openPositions .' 'openContact .' 'openSection openSection';
  }

  @media screen and (max-width: ${media.md}px) {
    width: auto;
    grid-template-columns: 1fr;
  }
`;

export const OpenTitle = styled.h2`
  grid-area: openTitle;

  margin-bottom: ${spacing.l}px;

  font-size: ${typography.fontSizes['xxxl+']}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1.06;

  color: white;
`;

export const OpenSub = styled.sub`
  grid-area: openSub;

  margin-bottom: ${spacing.xl}px;
  color: ${colors.accent};

  ${subStyle};
`;

export const OpenText = styled.p`
  grid-area: openText;

  margin-bottom: ${spacing.xl}px;

  color: ${colors.quaternary};
`;

export const OpenSection = styled.section`
  grid-area: openSection;

  width: 360px;
  height: 275px;
  box-sizing: border-box;

  padding: 50px 48px 45px;

  display: flex;
  flex-direction: column;

  border-radius: ${spacing.xs}px;
  background: linear-gradient(45deg, #052e40, #10526b);
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%);

  @media (max-width: ${media.xl}px) {
    width: auto;
    margin-top: 80px;
  }
`;

export const OpenSectionTitle = styled.h3`
  margin-bottom: ${spacing.xl}px;

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.medium};
  line-height: 1.25;

  @media (max-width: ${media.xl}px) {
    margin-bottom: 100px;
  }
`;

export const OpenSectionLink = styled.a`
  padding-top: ${spacing.l}px;

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.medium};
  text-decoration: none;

  border-top: 2px solid ${colors.accent};
`;

export const PositionContainer = styled.div`
  padding: ${spacing.xxxxl}px 0;

  p {
    padding-bottom: ${spacing.xxxxl}px;
  }

  h2 {
    text-align: center;
    padding-bottom: ${spacing.xxxxl}px;
  }

  h3 {
    padding-bottom: ${spacing.xxl}px;
    font-size: 18px;
  }

  ul {
    list-style: circle;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: ${colors.light};
    padding-bottom: ${spacing.xxxxl}px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 20px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 24px;
    }
  }
`;
