import React from 'react';

import { PositionContainer } from './MigrationStyles';

export const MigrationContent = () => {
  return (
    <PositionContainer>
      <header>
        <h2>FAQ - Oqcam is moving to oqcam.com</h2>
      </header>

      <section>
        <p>
          We are moving the Oqcam software to our own web domain instead of the one from Oqton. For you, the only change is that you will find the Oqcam app at{' '}
          <b>yourname.app.oqcam.com</b> instead of <b>yourname.eu2.oqton.ai</b>.
        </p>
      </section>

      <section>
        <h3>Why is this done?</h3>
        <p>
          Oqcam spun out as an independent company from Oqton last year. To finalize the separation, we need to move the
          Oqcam app to our own web domain.
        </p>

        <h3>When will this happen?</h3>
        <p>
          We move our customers one by one in the period between March 15th and April 30th, 2025. We will show a
          notification banner in the app on the day we will move your account, at least 6 hours before the move will start.
          The move will happen outside of regular working hours, i.e. between 20:00 - 6:00 local time, or during a weekend.
        </p>

        <h3>Will there be downtime?</h3>
        <p>
          We expect a short period of about 15 minutes between the calculations on the oqton.ai address being unavailable and the oqcam.com address being available.
          When we start the move, the oqton.ai address will still be accessible but you can only see existing data, not make
          changes or create new data. This is the reason we perform the move outside regular working hours: we want to limit the inconvenience for our customers as much as possible. In a few
          cases, the downtime period may be up to two hours.
        </p>

        <h3>How do I find my account on the new web domain?</h3>
        <p>
          You simply have to replace <em>eu2.oqton.ai</em> with <em>app.oqcam.com</em> in the website address. For
          instance, if you usually go to <em>dental-lab.eu2.oqton.ai</em>, you will find your account at{' '}
          <em>dental-lab.app.oqcam.com</em> after the move. The username and password are the same for both addresses.
          If you access your account on the old oqton.ai domain after
          the move, you will find a message explaining how to find the new domain.
        </p>

        <h3>How do I log in after the move?</h3>
        <p>
          You log in with the same username and password at app.oqcam.com as you did on eu2.oqton.ai. If you have
          problems logging in:
        </p>
        <p>
          <ul>
            <li>
              If you use a password manager such as 1password: You may need to open 1password, find the right entry
              (probably called Oqton, oqton.ai, Oqcam or similar) and manually copy or view the password from here.
            </li>
            <li>
              If your password is stored in Chrome: You can open the stored password entry by clicking the three dots at
              the upper right corner; choose "Passwords and autofill"; choose "Google Password Manager"; click on
              oqton.ai in the list (could also be called Oqton, Oqcam or similar); from here you can copy or view your
              username and password.
            </li>
            <li>As a last resort, you can always reset your password through yourname.app.oqcam.com using the Forgot Your Password link.</li>
          </ul>
        </p>
      </section>
    </PositionContainer>
  );
};
