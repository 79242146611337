import React from 'react';

import { Icon } from '../../subComponents/Icon';
import { ContentContainer, Title, Sub, IntroText, IntroLink } from './MigrationStyles';

export const MigrationHeader = ({ content }) => {
  return (
    <ContentContainer>
      <Title>{content.title}</Title>
      <Sub>{content.subtitle}</Sub>
    </ContentContainer>
  );
};
